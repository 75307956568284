<template lang="pug">
//- file:///D:/Current%20work/WebDev/JavaScript/horizontal-scrolling-section/index.html
#reviews.reviews.section
	//- #reviewsinfo
	.container

		.horiz-scroll(ref="horizScrollSec")
			.horiz-scroll__container(ref="horizScrollContainer")

				.horiz-scroll__header(ref="headerHorizScrollSec")

					h2.sec-headline
						span(data-anima="textblind")
							span.textblind__row
								span.textblind__item Happy&nbsp;
									span.text-accent c
									| lients
									span.text-small (0{{allReviews.length}})

				.horiz-scroll__items(ref="horizScrollItems")
					.horiz-scroll__item(v-for="review in reviews" :key="review.id")
						.horiz-scroll__item-img
							img(v-if="review.avatar" :src="`/img/${review.avatar}`" :alt='review.author')
							img(v-else src="/img/review.jpg")
						h4 {{review.author}}
						p {{review.position}}
						//- p {{message}} / {{review.position}} / {{messageHeight}}
						p.reviews__text(v-html="decoratedText(review.text)")

				.horiz-scroll__footer.tablet
					.btn.btn_secondary(@click.prevent="showModal")
						span Add review

		.reviews__showall-btn
			.link(ref="showallbtn" @click.prevent="toggleAllReviews")
				span Show all
				span Collapse Reviews

		.horiz-scroll__footer
			.btn.btn_secondary(@click.prevent="showModal")
				span Add review

	ReviewsModal(
		:visible="isModalVisible"
		:qtyReviews="allReviews.length"
		@close="closeModal")
		//- @addAnotherReview="addReview")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
	name: 'Reviews',

	components: {
		ReviewsModal: () => import("@/components/front/ReviewsModal")
	},

	data: () => ({
		reviews: [
			{id: 1, avatar: "review-1.jpg", author: 'Author', position: '', text: ''},
			{id: 2, avatar: "review-1.jpg", author: 'Author', position: '', text: ''},
			{id: 3, avatar: "review-1.jpg", author: 'Author', position: '', text: ''},
			{id: 4, avatar: "review-1.jpg", author: 'Author', position: '', text: ''},
			{id: 5, avatar: "review-1.jpg", author: 'Author', position: '', text: ''}
		],
		horizScrollSec: null,
		horizScrollItems: null,
		horizScrollContainer: null,
		scrollWidth: 0,  // Full width horizScrollContainer
		verticalScrollHeight: 0,  // Разница между высотой всей скролл секции и высотой видимого контейнера
		isModalVisible: false,
		reviewItemSize: 0,
		widthWindow: 0,
		scrollBarWidth: 0
		// isHorizontalScrollInit: false
		// message: '',
		// messageHeight: ''
	}),

	computed: {
		...mapGetters("reviews", ["allReviews"])
	},

	watch: {
		// Update Reviews когда добавили отзыв
		allReviews() { // изменяется только, когда добавляется отзыв на Фронте
			// Чтобы обновить, когда удалили отзыв, нужно перезагрузить страницу

			if (this.allReviews.length) {
				this.$emit('changeQtyReviews', this.reviewItemSize);
				this.reviews = Object.assign([], this.allReviews);

				this.downloadReviews();
				// this.setHeightScrollSec();
			}
		}
	},

	created() {
		if (!this.allReviews.length) this.reviews = Object.assign([], this.fetchReviews());
		else this.reviews = Object.assign([], this.allReviews);
		this.$emit('reviewsOnload');
	},

	beforeDestroy() {
		// remove listener again
		if (this.widthWindow > 767.9) {
			window.removeEventListener('resize', this.setHeightScrollSec);
			window.removeEventListener('scroll', this.initHorizontalScroll);
		}
	},

	mounted() {
		this.$nextTick( () => {
			this.horizScrollSec = this.$refs['horizScrollSec'];
			this.horizScrollItems = this.$refs['horizScrollItems'];
			this.horizScrollContainer = this.$refs['horizScrollContainer'];

			this.widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

			if (this.widthWindow >= 768) {
				// const verticalScrollHeight = this.horizScrollSec.getBoundingClientRect().height - this.horizScrollContainer.getBoundingClientRect().height;
				// console.log('verticalScrollHeight (mounted) = ', verticalScrollHeight);

				if (this.allReviews.length) this.downloadReviews();

				document.addEventListener('scroll', this.initHorizontalScroll);
				window.addEventListener('resize', this.setHeightScrollSec);
			}
		});
	},

	methods: {
		...mapActions("reviews", ["fetchReviews"]),
		...mapMutations("tooltip", ["SHOW_TOOLTIP"]),

		downloadReviews() {
			// const reviewItem = [...this.horizScrollItems.children][0];
			const reviewItem = [...this.horizScrollItems.children][this.allReviews.length - 1];
			// console.log('this.reviews.length / reviewItem ', this.reviews.length, reviewItem);

			if (reviewItem) {
				this.horizScrollItems.scrollLeft = 0;

				this.setHeightScrollSec();
				// console.log('Reviews download!');
				this.scrollWidth = this.horizScrollContainer.scrollWidth;

				this.verticalScrollHeight =
					this.horizScrollSec.getBoundingClientRect().height -
					this.horizScrollContainer.getBoundingClientRect().height;
				// console.log('verticalScrollHeight (downloadReviews) = ', Math.trunc(this.verticalScrollHeight));

				// document.addEventListener('scroll', this.initHorizontalScroll);

			}	else {
				setTimeout( () => {
					this.downloadReviews();
				}, 10);
			}
		},

		setHeightScrollSec() {
			this.reviewItemSize = [...this.horizScrollItems.children][0].offsetHeight;
			// this.reviewItemSize = [...this.horizScrollItems.children][0].offsetWidth;

			const headerHeight = this.$refs['headerHorizScrollSec'].offsetHeight;

			// let factor = 1.5;
			// if (this.widthWindow <= 1400) factor = 2;
			// if (this.widthWindow <= 1200) factor = 2.5;
			// if (this.widthWindow <= 1024) factor = 3;

			let reviewSecHeight = this.allReviews.length * this.reviewItemSize + headerHeight;

			// reviewSecHeight = reviewSecHeight * factor;
			reviewSecHeight = reviewSecHeight * this.allReviews.length / (this.allReviews.length - 0.7);

			// console.log('reviewSecHeight = ', reviewSecHeight);
			// this.messageHeight = Math.trunc(reviewSecHeight);

			this.horizScrollSec.style.height = Math.trunc(reviewSecHeight) + 'px';
		},

		// file:///D:/Current%20work/WebDev/JavaScript/horizontal-scrolling-section/index.html
		initHorizontalScroll() {
			//Checking whether the sticky element has entered into view or not
			let horizScrollPosition = this.horizScrollContainer.getBoundingClientRect().top;

			if (horizScrollPosition > 30) {
				this.horizScrollItems.scrollLeft = 0;
				// this.message = this.horizScrollItems.scrollLeft;
				return;

			} else {
				const scrolled = this.horizScrollSec.getBoundingClientRect().top; //how much is scrolled?

				let factor = 0.5;
				if (this.widthWindow <= 1400) factor = 0.58;
				if (this.widthWindow <= 1200) factor = 0.75;
				if (this.widthWindow <= 1024) factor = 0.89;
				if (this.widthWindow <= 800) factor = 0.897;

				// const scrollLeft = (this.scrollWidth / this.verticalScrollHeight) * (-scrolled) * 5.4;
				let scrollLeft = (this.scrollWidth / this.verticalScrollHeight) * (-scrolled) * factor * this.allReviews.length - 130;

				// this.message = Math.trunc(scrollLeft);

				this.horizScrollItems.scrollLeft = Math.trunc(scrollLeft);

				this.horizScrollItems.setAttribute('data-left', Math.trunc(scrollLeft));
			}
		},

		showModal() {
			this.scrollBarWidth = window.innerWidth - document.getElementsByTagName('html')[0].clientWidth;

			this.isModalVisible = true;
			document.body.classList.add('noscroll');
			document.body.style.paddingRight = this.scrollBarWidth + 'px';
		},

		closeModal() {
			this.isModalVisible = false;
			document.body.classList.remove('noscroll');
			document.body.style.paddingRight = '0';
		},

		decoratedText(text) {
			const textItems = text.split(' ');
			const random = Math.floor(Math.random() * textItems.length);
			let newText = '';

			textItems.forEach( (item, index) => {
				if (index + 1 === random) newText += '<span class="text-accent color-success">' + item + '</span> ';
				else newText += item + ' ';
			});

			return newText.trim();
		},

		toggleAllReviews() {
			this.$refs['showallbtn'].classList.toggle('collapse');
			this.horizScrollItems.classList.toggle('allreviews-visible');
		}
	}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.reviews.section {
	overflow: visible;
	background-color: $bg-color-beige;
	padding: 70px 0 140px;

	@include phone() {
		padding: 70px 0 100px;
		background-color: #151515;
		color: $bg-color-beige;
	}

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		top: -50px;
		left: 0;
		height: 150px;
		background: url('/img/icons/sec-border.svg');
		background-size: cover;
		transform: scaleX(-1);

		@include phone() {
			background: url('/img/icons/sec-border-dark.svg');
			background-size: cover;
		}
	}

	.reviews__showall-btn {
		display: none;

		@include tablet() {
			display: block;
			margin: 0 0 100px;
			text-align: center;
		}

		.link {
			display: inline-block;

			span:nth-child(1) {
				display: block;
			}

			span:nth-child(2) {
				display: none;
			}

			&.collapse {
				span:nth-child(1) {
					display: none;
				}

				span:nth-child(2) {
					display: block;
				}
			}

			@include phone() {
				color: $bg-color-beige;
			}
		}
	}

	.horiz-scroll__footer {
		margin: 30px 0 0 0;
		text-align: center;

		.btn {
			width: 100%;
		}

		@include netbook() {
			display: none;
		}

		@include tablet() {
			display: block;
		}

		&.tablet {
			display: none;

			@include netbook() {
				display: block;
				margin-top: 60px;
			}

			@include tablet() {
				display: none;
			}
		}
	}
}

.horiz-scroll {
	padding: 100px 0 100px;
	/* height: 600vh;  Подбираем такую высоту, чтоб успели до конца прокрутиться все items */

	@include netbook() {
		padding: 100px 0 30px;
	}

	@include tablet() {
		height: auto !important;
		padding: 100px 0 50px;
	}

	.horiz-scroll__container {
		position: sticky;
		top: 30px;
		max-height: calc(55vh + 180px);

		@include tablet() {
			position: relative;
			max-height: 15000px;
		}

		.horiz-scroll__header {
			padding-bottom: 45px;
		}

		.horiz-scroll__items {
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			max-height: calc(55vh + 180px);
			overflow-x: hidden;
			overflow-y: hidden;

			.horiz-scroll__item {
				display: block;
				min-width: 50vw;
				// height: 55vh;
				height: 415px;
				margin: 0 5px 0 0;
				padding: 3em;
				border: 1px solid $lines-color;
				border-radius: 15px;
				background: $bg-color-beige;
				transition: all 0.2s ease-out;

				@include desktop() {
					min-width: 60vw;
				}

				@include notebook() {
					min-width: 70vw;
				}

				@include netbook() {
					min-width: calc(86vw - 4px);
				}

				@include tablet() {
					height: auto;
					min-height: 150px;
					margin: 0 0 10px;
					padding: 3em 3em 4.5em;
					display: none;

					&:nth-child(1), &:nth-child(2) {
						display: block;
					}
				}

				@include phone() {
					color: $text-color;
				}

				@include phoneSmall() {
					padding: 1.5em 1.5em 2.5em;
				}

				&:last-child {
					margin: 0 2px 0 0;
				}

				.horiz-scroll__item-img {
					float: left;
					margin: 0 30px 10px 0;
					width: 70px;
					height: 70px;
					border-radius: 50%;

					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						transition: all 0.3s ease-out;
					}
				}

				h4 {
					margin: 3px 0 0 0;
					font-size: 2rem;
					font-weight: 600;
				}

				p {
					margin: 0;
					min-height: 17px;
					font-size: 0.9rem;

					&.reviews__text {
						margin: 40px 0 0 5px;
						font-size: 1.3rem;
						line-height: 1.42;
					}
				}

				&:hover {
					// background-color: $text-color;
					border: 1px solid $links-color;

					.horiz-scroll__item-img {
						background-color: $links-color;

						img {
							transform: scale(0.75);
						}
					}

					h4, p {
						// color: $bg-color-beige;
					}
				}
			}

			@include tablet() {
				flex-direction: column;
				max-height: 15000px;

				&.allreviews-visible {
					.horiz-scroll__item {
						display: block;
					}
				}
			}
		}
	}
}

</style>
